<template>
  <v-app id="inspire">
    <v-main>
      <div class="tw-w-full tw-flex tw-h-full">
        <v-col
          class="
            image
            tw-hidden
            lg:tw-block
            image
            tw-h-auto tw-flex tw-justify-center
          "
          cols="12"
          sm="12"
          md="7"
        >
        </v-col>
        <v-col
          class="
            tw-h-auto tw-px-6
            md:tw-px-24
            tw-flex tw-flex-wrap tw-items-center tw-justify-center
          "
          id="login"
          cols="12"
          sm="12"
          md="5"
        >
          <v-card class="tw-w-full elevation-3 tw-mt-8 tw-py-4">
            <div class="tw-w-full tw-flex tw-justify-center">
              <img
                src="/apalife-logo.svg"
                class="tw-w-24 md:tw-w-32 tw-mt-4 md:tw-mt-6"
                alt="logo"
              />
            </div>
            <v-form v-model="isValid" ref="form" @submit.prevent="submitForm">
              <v-card-text class="tw-px-4 md:tw-px-10">
                <v-text-field
                  id="username"
                  class="my-5"
                  label="Agent code"
                  type="number"
                  name="username"
                  v-model="form.username"
                  :rules="[required('Agent code')]"
                >
                  <v-icon slot="prepend" color="primary">mdi-account</v-icon>
                </v-text-field>
                <v-text-field
                  id="password"
                  label="Password"
                  :type="passwordField"
                  v-model="form.password"
                  :rules="[required('Password')]"
                >
                  <v-icon slot="prepend" color="primary">mdi-lock</v-icon>
                  <v-icon
                    slot="append"
                    color="primary"
                    class="tw-cursor-pointer"
                    @click="
                      passwordField =
                        passwordField === 'password' ? 'text' : 'password'
                    "
                    >{{
                      passwordField === 'password' ? 'mdi-eye' : 'mdi-eye-off'
                    }}</v-icon
                  >
                </v-text-field>
              </v-card-text>

              <v-card-actions class="col-sm-12 tw-px-4 md:tw-px-10">
                <v-btn
                  block
                  large
                  id="signIn"
                  type="submit"
                  color="primary"
                  class="my-3"
                  @click="loading = true"
                  :loading="loading"
                  :disabled="!isValid"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-form>
            <div class="tw-grid tw-justify-items-stretch" >
          <div class="tw-justify-self-end tw-mr-2">
            <v-btn text class="primary--text tw-font-bold" router to="/reset-password">Forgot Password ?</v-btn>
          </div>
          </div>
          </v-card>
          <div class="tw-w-full">
            <div
              class="
                tw-w-full tw-flex tw-items-center
                c-gray-text
                tw-text-center
              "
            >
              <div class="tw-w-5/12">
                <hr />
              </div>
              <div class="tw-w-2/12">OR</div>
              <div class="tw-w-5/12">
                <hr />
              </div>
            </div>
            <get-quote :query="query" />
          </div>
          <div
            class="
              tw-w-full tw-flex tw-absolute tw-right-auto
              lg:tw-right-0
              tw-bottom-0
            "
          >
            <div class="tw-hidden lg:tw-block tw-w-full lg:tw-w-7/12"></div>
            <div class="tw-w-full lg:tw-w-5/12 tw-grid tw-grid-cols-2">
              <div class="c-bg-blue tw-py-1"></div>
              <div class="c-bg-red tw-py-1"></div>
            </div>
          </div>
        </v-col>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import validations from '../../utils/validations';
import GetQuote from '@/components/GetQuote';

export default {
  name: 'SignIn',
  metaInfo: {
    title: 'Sign In',
  },
  components: { GetQuote },
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      ...validations,
      loading: false,
      isValid: false,
      query: '',
      passwordField: 'password',
    };
  },
  methods: {
    async submitForm() {
      const formData = this.form;
      await this.$store.dispatch('auth/login', formData);
      await this.$nextTick();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  // background-image: url('/images/family.jpg');
  background-image: url('https://d1pdlda3c1busn.cloudfront.net/family.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: cover;
}
</style>
