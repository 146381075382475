/* eslint no-mixed-operators:0 */
/* eslint no-shadow:0 */
import helpers from './helpers';

const required = (propertyType) => (v) => v && v.length > 0 || `${propertyType} field is required`;

const minLength = (propertyType, minimumLength) => (v) => (v === '' || v && v.length >= minimumLength) || `${propertyType} must not be less than ${minimumLength} characters`;

const confirmPassword = (propertyType, password) => (v) => v && v === password || `${propertyType} must be same as entered password`;

const maxLength = (propertyType, maximumLength) => (v) => v && v.length <= maximumLength || `${propertyType} must be less than ${maximumLength}`;

const max = (propertyType, maximum) => (v) => v && v <= maximum || `${propertyType} must not be more than ${maximum}`;

const minmax = (propertyType, minimum, maximum) => (v) => v && v >= minimum && v <= maximum || `${propertyType} must not be less than ${minimum} or more than ${maximum}`;

const min = (propertyType, minimum) => (v) => v && v >= minimum || `${propertyType} must be ${minimum} or more`;

const minAmount = (propertyType, num) => (v) => helpers.stringToNumber(v) && helpers.stringToNumber(v) >= num || `${propertyType} must be ${helpers.numberToString(num)} or more`;
const emailFormat = () => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (v) => v && regex.test(v) || 'Must be a valid email';
};

const mobileFormat = () => {
  const regex = /^\+254\d{9}$/; // [- ]?
  return (v) => v && regex.test(v) || 'Must start with +254...';
};

const kraPinFormat = () => {
  const regex = /^a\d{9}[a-z]$/i;
  return (v) => v && regex.test(v) || 'Must be a valid KRA PIN';
};

const numberFormat = () => {
  const regex = /^\d+$/;
  return (v) => v && regex.test(v) || 'Must be a valid number';
};

const lowerCaseFormat = () => {
  const regex = /^(.*[a-z].*)$/;
  return (v) => v && regex.test(v) || 'Must have at least 1 lowercase character';
};
const upperCaseFormat = () => {
  const regex = /^(.*[A-Z].*)$/;
  return (v) => v && regex.test(v) || 'Must have at least 1 uppercase character';
};

const digitFormat = () => {
  const regex = /^(.*\d.*)$/;
  return (v) => v && regex.test(v) || 'Must have at least 1 digit';
};

const specialCharFormat = () => {
  const regex = /^(.*[!@#$%^&*)(+=.,_-].*)$/;
  return (v) => v && regex.test(v) || 'Must have at least 1 special character';
};

const numberPlateFormat = () => {
  const regex = /^[A-Za-z]{3}(.*?)\d{3}(.*?)[A-Za-z]{1}$/;
  return (v) => v && regex.test(v) || 'Registration Number must be valid';
};

const isObjectField = () => (v) => v && typeof v === 'object' || 'Must be valid object';

const check = () => (v) => v === true || 'You must agree to continue!';

export default {
  required,
  minLength,
  maxLength,
  emailFormat,
  numberFormat,
  kraPinFormat,
  confirmPassword,
  check,
  mobileFormat,
  lowerCaseFormat,
  minAmount,
  max,
  minmax,
  min,
  isObjectField,
  numberPlateFormat,
  upperCaseFormat,
  digitFormat,
  specialCharFormat,
};
